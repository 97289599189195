.ruby-blog__author-page-container__content {
  flex: 2;
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
}

.ruby-blog__author-page-container__content__author-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem;

  margin-bottom: 1rem;
}

.ruby-blog__author-page-container__content__image-div {
  width: 40px;
  height: 40px;
}

.ruby-blog__author-page-container__content__image-div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ruby-blog__author-page-container__content__author-name-div {
  margin-left: 1rem;
  color: var(--dark-gray-color);
  font-size: 20px;
  font-family: var(--font-alt);
  font-weight: 700;
  text-transform: capitalize;
}
