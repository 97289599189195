.ruby-blog__header-container {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;

  position: relative;
}

.ruby-blog__header-container__logo {
  flex: 1;
  font-size: 25px;
  font-family: var(--font-alt);
  font-weight: 700;
}

.ruby-blog__header-container__nav {
  flex: 1.8;
  display: flex;
}

.ruby-blog__header-container__nav ul {
  display: flex;
}

.ruby-blog__header-container__nav ul li {
  list-style: none;
  margin: 0 1.5rem;
  font-family: var(--font-base);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--dark-gray-color);
  cursor: pointer;
}

.ruby-blog__header-container__nav-smallDevice {
  display: none;
}

.ruby-blog__header-container__nav-smallDevic__sidebar-open {
  display: flex;
}

@media screen and (max-width: 500px) {
  .ruby-blog__header-container__nav {
    display: none;
  }

  .ruby-blog__header-container__nav-smallDevice {
    display: flex;
  }
}

.ruby-blog__header-container__nav-smallDevice__sidebar {
  background: var(--light-gray-color);
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 75vw;
  height: 100vh;
  left: 25%;
  top: 0%;
  z-index: 10;

  padding: 1rem;
  overflow-y: scroll;
  box-shadow: -1px 0px 15px rgb(170, 170, 170, 0.15);
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.ruby-blog__header-container__nav-smallDevice__sidebar-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__navlinks {
  display: flex;
  margin: 1rem 0rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__navlinks ul {
  display: flex;
  flex-direction: column;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__navlinks ul li {
  list-style: none;
  margin: 0.5rem 0rem;
  text-transform: uppercase;
  font-size: 12px;
  font-family: var(--font-base);
  font-weight: 700;
  cursor: pointer;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__tags {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__tags-heading,
.ruby-blog__header-container__nav-smallDevice__sidebar-content__recentPosts-heading {
  font-size: 15px;
  font-family: var(--font-alt);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--secondary-text-color);

  margin-bottom: 0.7rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__tagsList {
  display: flex;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__recentPosts {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__recentPosts-posts {
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
}
