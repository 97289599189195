* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

.div__tag {
  display: flex;

  margin-bottom: 0.5rem;
  padding: 1rem 1rem 0 1rem;
}

.div__tag div {
  background: var(--black-color);
  color: var(--white-color);
  font-family: var(--font-base);
  font-size: 12px;
  font-weight: 700;
  padding: 0.15rem 0.5rem;
  border-radius: 15px;
  text-transform: capitalize;

  cursor: pointer;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  background: #0099ff;
  border: 4px solid #0099ff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
