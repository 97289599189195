.basic-article-card__container {
  width: 270px;
  background: var(--white-color);

  margin: 1rem 0.9rem;

  display: flex;
  flex-direction: column;

  border-radius: 7px;
  transition: box-shadow 0.3s var(--animation-base);

  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.basic-article-card__container:hover {
  box-shadow: var(--shadow-base);
}

.basic-article-card__container__image-div {
  width: 100%;
  height: 150px;
}

.basic-article-card__container__image-div img {
  width: 100%;
  height: 100%;

  cursor: pointer;

  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.basic-article-card__container__content-div {
  display: flex;
  flex-direction: column;
}

.basic-article-card__container__content-div__title {
  display: flex;
  text-align: left;

  padding: 0 1rem;
  margin-bottom: 1rem;
}

.basic-article-card__container__content-div__title h2 {
  color: var(--dark-gray-color);
  font-family: var(--font-alt);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.basic-article-card__container__content-div__para {
  display: flex;
  text-align: left;

  padding: 0rem 1rem;
}

.basic-article-card__container__content-div__para p {
  font-size: 14px;
  color: var(--secondary-text-color);
  font-family: var(--font-base);
  line-height: 1.65;
  word-break: break-word;
}

.basic-article-card__container__content-div__end {
  margin-top: 1.25rem;
  padding: 0.5rem 0.7rem;
  border-top: 1px solid var(--light-gray-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basic-article-card__container__content-div__end-readMore {
  display: flex;
  text-align: left;
}

.basic-article-card__container__content-div__end-authors {
  display: flex;
}

.basic-article-card__container__content-div__end-readMore p {
  font-size: 11px;
  text-transform: uppercase;
  font-family: var(--font-base);
  font-weight: 700;
  color: var(--dark-gray-color);

  cursor: pointer;
}

.basic-article-card__container__content-div__end-authors__circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.basic-article-card__container__content-div__end-authors__circle img {
  width: 100%;
  height: 100%;
  border-radius: 100%;

  cursor: pointer;
}

@media screen and (max-width: 300px) {
  .basic-article-card__container {
    margin: 1rem 0;
  }
}
