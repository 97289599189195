.recentPosts-post {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0.5rem 0rem;
}

.recentPosts-post h5 {
  font-family: var(--font-alt);
  font-size: 16px;
  color: var(--dark-gray-color);
  font-weight: 600;
  margin-bottom: 3px;
}

.recentPosts-post p {
  font-size: 11px;
  font-family: var(--font-base);
  text-transform: uppercase;
  color: var(--secondary-text-color);
  font-weight: 700;
}
