@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;600;700;800&display=swap");

:root {
  --primary-color: (#15171a, #2ec4b6);
  --primary-text-color: #333;
  --secondary-text-color: #aaa;
  --white-color: #fff;
  --light-gray-color: #f4f4f4;
  --mid-gray-color: #e9e9e9;
  --dark-gray-color: #1a1a1a;
  --black-color: #000;
  --font-base: "Open Sans", sans-serif;
  --font-alt: dosis, sans-serif;
  --animation-base: ease-in-out;
  --shadow-base: 0 0 15px rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(-0px);
    transform: translateY(0px);
  }
}
