.ruby-blog__home-container {
  background: #f9f9f9;
  padding: 2rem 5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;
}

.ruby-blog__home-container__content {
  flex: 2;
  margin-right: 1rem;
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
}

.ruby-blog__home-container__content-article-div {
  display: flex;
  flex-wrap: wrap;
}

.ruby-blog__home-container__content-spinner-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
}

.ruby-blog__home-container__sideBar {
  flex: 0.6;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 90vh;

  padding-top: 2rem;
}

@media screen and (max-width: 990px) {
  .ruby-blog__home-container__sideBar {
    display: none;
  }

  .ruby-blog__home-container__content {
    flex: 1;
    margin-right: 0;
  }

  .ruby-blog__home-container__content-article-div {
    justify-content: center;
  }
}

@media screen and (max-width: 760px) {
  .ruby-blog__home-container {
    padding: 1rem;
  }
}

@media screen and (max-width: 300px) {
  .ruby-blog__home-container {
    padding: 1rem 0.5rem;
  }
}
