.ruby_blog__shortcutBar-container__tagsBar,
.ruby_blog__shortcutBar-container__recentPostsBar {
  background: #fff;

  display: flex;
  flex-direction: column;

  margin-bottom: 3rem;
  border-radius: 7px;

  transition: box-shadow 0.3s ease-in-out;
}

.ruby_blog__shortcutBar-container__tagsBar:hover,
.ruby_blog__shortcutBar-container__recentPostsBar:hover {
  box-shadow: var(--shadow-base);
}

.ruby_blog__shortcutBar-container__tagsBar-head,
.ruby_blog__shortcutBar-container__recentPostsBar-head {
  border-bottom: 1px solid var(--light-gray-color);
  padding: 0.7rem 1rem;
}

.ruby_blog__shortcutBar-container__tagsBar-head p,
.ruby_blog__shortcutBar-container__recentPostsBar-head p {
  font-family: var(--font-base);
  color: var(--secondary-text-color);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.ruby-blog__shortcutBar-container__tagsBar-content,
.ruby-blog__shortcutBar-container__recentPostsBar-content {
  padding: 0.5rem 1rem;
}

.ruby-blog__shortcutBar-container__tagsBar-content .taglist-tag {
  margin: 1rem 0;
}

.ruby-blog__shortcutBar-container__recentPostsBar-content {
  margin-bottom: 1.25rem;
  padding-left: 1.2rem;
}
