@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;600;700;800&display=swap);
:root {
  --primary-color: (#15171a, #2ec4b6);
  --primary-text-color: #333;
  --secondary-text-color: #aaa;
  --white-color: #fff;
  --light-gray-color: #f4f4f4;
  --mid-gray-color: #e9e9e9;
  --dark-gray-color: #1a1a1a;
  --black-color: #000;
  --font-base: "Open Sans", sans-serif;
  --font-alt: dosis, sans-serif;
  --animation-base: ease-in-out;
  --shadow-base: 0 0 15px rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(-0px);
    transform: translateY(0px);
  }
}

.recentPosts-post {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0.5rem 0rem;
}

.recentPosts-post h5 {
  font-family: var(--font-alt);
  font-size: 16px;
  color: var(--dark-gray-color);
  font-weight: 600;
  margin-bottom: 3px;
}

.recentPosts-post p {
  font-size: 11px;
  font-family: var(--font-base);
  text-transform: uppercase;
  color: var(--secondary-text-color);
  font-weight: 700;
}

.taglist-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.7rem 0rem;

  cursor: pointer;
}

.taglist-tag p {
  font-family: var(--font-alt);
  text-transform: capitalize;
  color: var(--dark-gray-color);
  font-weight: 600;
  font-size: 15px;
}

.taglist-tagCount {
  display: flex;
}

.taglist-tagCount div {
  width: 20px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
}

.ruby-blog__header-container {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;

  position: relative;
}

.ruby-blog__header-container__logo {
  flex: 1 1;
  font-size: 25px;
  font-family: var(--font-alt);
  font-weight: 700;
}

.ruby-blog__header-container__nav {
  flex: 1.8 1;
  display: flex;
}

.ruby-blog__header-container__nav ul {
  display: flex;
}

.ruby-blog__header-container__nav ul li {
  list-style: none;
  margin: 0 1.5rem;
  font-family: var(--font-base);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--dark-gray-color);
  cursor: pointer;
}

.ruby-blog__header-container__nav-smallDevice {
  display: none;
}

.ruby-blog__header-container__nav-smallDevic__sidebar-open {
  display: flex;
}

@media screen and (max-width: 500px) {
  .ruby-blog__header-container__nav {
    display: none;
  }

  .ruby-blog__header-container__nav-smallDevice {
    display: flex;
  }
}

.ruby-blog__header-container__nav-smallDevice__sidebar {
  background: var(--light-gray-color);
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 75vw;
  height: 100vh;
  left: 25%;
  top: 0%;
  z-index: 10;

  padding: 1rem;
  overflow-y: scroll;
  box-shadow: -1px 0px 15px rgb(170, 170, 170, 0.15);
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.ruby-blog__header-container__nav-smallDevice__sidebar-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__navlinks {
  display: flex;
  margin: 1rem 0rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__navlinks ul {
  display: flex;
  flex-direction: column;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__navlinks ul li {
  list-style: none;
  margin: 0.5rem 0rem;
  text-transform: uppercase;
  font-size: 12px;
  font-family: var(--font-base);
  font-weight: 700;
  cursor: pointer;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__tags {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__tags-heading,
.ruby-blog__header-container__nav-smallDevice__sidebar-content__recentPosts-heading {
  font-size: 15px;
  font-family: var(--font-alt);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--secondary-text-color);

  margin-bottom: 0.7rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__tagsList {
  display: flex;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__recentPosts {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}

.ruby-blog__header-container__nav-smallDevice__sidebar-content__recentPosts-posts {
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
}

.basic-article-card__container {
  width: 270px;
  background: var(--white-color);

  margin: 1rem 0.9rem;

  display: flex;
  flex-direction: column;

  border-radius: 7px;
  transition: box-shadow 0.3s var(--animation-base);

  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.basic-article-card__container:hover {
  box-shadow: var(--shadow-base);
}

.basic-article-card__container__image-div {
  width: 100%;
  height: 150px;
}

.basic-article-card__container__image-div img {
  width: 100%;
  height: 100%;

  cursor: pointer;

  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.basic-article-card__container__content-div {
  display: flex;
  flex-direction: column;
}

.basic-article-card__container__content-div__title {
  display: flex;
  text-align: left;

  padding: 0 1rem;
  margin-bottom: 1rem;
}

.basic-article-card__container__content-div__title h2 {
  color: var(--dark-gray-color);
  font-family: var(--font-alt);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.basic-article-card__container__content-div__para {
  display: flex;
  text-align: left;

  padding: 0rem 1rem;
}

.basic-article-card__container__content-div__para p {
  font-size: 14px;
  color: var(--secondary-text-color);
  font-family: var(--font-base);
  line-height: 1.65;
  word-break: break-word;
}

.basic-article-card__container__content-div__end {
  margin-top: 1.25rem;
  padding: 0.5rem 0.7rem;
  border-top: 1px solid var(--light-gray-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basic-article-card__container__content-div__end-readMore {
  display: flex;
  text-align: left;
}

.basic-article-card__container__content-div__end-authors {
  display: flex;
}

.basic-article-card__container__content-div__end-readMore p {
  font-size: 11px;
  text-transform: uppercase;
  font-family: var(--font-base);
  font-weight: 700;
  color: var(--dark-gray-color);

  cursor: pointer;
}

.basic-article-card__container__content-div__end-authors__circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.basic-article-card__container__content-div__end-authors__circle img {
  width: 100%;
  height: 100%;
  border-radius: 100%;

  cursor: pointer;
}

@media screen and (max-width: 300px) {
  .basic-article-card__container {
    margin: 1rem 0;
  }
}

.fancy-article-card__container {
  width: 270px;
  height: 368px;
  position: relative;

  margin: 1rem 0.9rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border-radius: 7px;
  transition: box-shadow 0.3s var(--animation-base);

  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fancy-article-card__container::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancy-article-card__container:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.fancy-article-card__container__image-div {
  width: 100%;
  height: 100%;
  border-radius: 7px;

  display: flex;
}

.fancy-article-card__container__image-div img {
  width: 100%;
  height: 100%;

  border-radius: 7px;
}

.fancy-article-card__container__content-div {
  position: absolute;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.fancy-article-card__container__content-div
  .basic-article-card__container__content-div__title
  h2 {
  color: var(--white-color);
  font-size: 22px;
}

.fancy-article-card__container__content-div
  .basic-article-card__container__content-div__title {
  margin-bottom: 0;
}

.fancy-article-card__container__content-div
  .basic-article-card__container__content-div__end-readMore
  p {
  color: var(--white-color);
}

.fancy-article-card__container__content-div
  .basic-article-card__container__content-div__end {
  border-color: rgba(244, 244, 244, 0.466);
}

@media screen and (max-width: 300px) {
  .fancy-article-card__container {
    margin: 1rem 0;
  }
}

.ruby-blog__primary-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.ruby-blog__primary-button__container-btn {
  background-color: var(--black-color);
  box-shadow: rgba(0, 0, 0, 0.37) 0px 2px 3px;
  border-radius: 30px;
  outline: none;
  border: none;
  color: var(--white-color);
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--font-base);
  line-height: 1;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: #000 radial-gradient(circle, transparent 1%, #000 1%)
    center/15000%;
}

.ripple:active {
  background-color: #ffffff;
  background-size: 100%;
  transition: background 0s;
}

.ruby_blog__shortcutBar-container__tagsBar,
.ruby_blog__shortcutBar-container__recentPostsBar {
  background: #fff;

  display: flex;
  flex-direction: column;

  margin-bottom: 3rem;
  border-radius: 7px;

  transition: box-shadow 0.3s ease-in-out;
}

.ruby_blog__shortcutBar-container__tagsBar:hover,
.ruby_blog__shortcutBar-container__recentPostsBar:hover {
  box-shadow: var(--shadow-base);
}

.ruby_blog__shortcutBar-container__tagsBar-head,
.ruby_blog__shortcutBar-container__recentPostsBar-head {
  border-bottom: 1px solid var(--light-gray-color);
  padding: 0.7rem 1rem;
}

.ruby_blog__shortcutBar-container__tagsBar-head p,
.ruby_blog__shortcutBar-container__recentPostsBar-head p {
  font-family: var(--font-base);
  color: var(--secondary-text-color);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.ruby-blog__shortcutBar-container__tagsBar-content,
.ruby-blog__shortcutBar-container__recentPostsBar-content {
  padding: 0.5rem 1rem;
}

.ruby-blog__shortcutBar-container__tagsBar-content .taglist-tag {
  margin: 1rem 0;
}

.ruby-blog__shortcutBar-container__recentPostsBar-content {
  margin-bottom: 1.25rem;
  padding-left: 1.2rem;
}

.ruby-blog__home-container {
  background: #f9f9f9;
  padding: 2rem 5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;
}

.ruby-blog__home-container__content {
  flex: 2 1;
  margin-right: 1rem;
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
}

.ruby-blog__home-container__content-article-div {
  display: flex;
  flex-wrap: wrap;
}

.ruby-blog__home-container__content-spinner-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
}

.ruby-blog__home-container__sideBar {
  flex: 0.6 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 90vh;

  padding-top: 2rem;
}

@media screen and (max-width: 990px) {
  .ruby-blog__home-container__sideBar {
    display: none;
  }

  .ruby-blog__home-container__content {
    flex: 1 1;
    margin-right: 0;
  }

  .ruby-blog__home-container__content-article-div {
    justify-content: center;
  }
}

@media screen and (max-width: 760px) {
  .ruby-blog__home-container {
    padding: 1rem;
  }
}

@media screen and (max-width: 300px) {
  .ruby-blog__home-container {
    padding: 1rem 0.5rem;
  }
}

.ruby-blog__article-layout__container__head {
  padding: 1rem;

  display: flex;
  flex-direction: column;

  border-bottom: 1px solid var(--light-gray-color);
}

.ruby-blog__article-layout__container__head-title {
  display: flex;
  text-align: left;

  padding-left: 1rem;
}

.ruby-blog__article-layout__container__head-title h2 {
  font-size: 22px;
  font-family: var(--font-alt);
  font-weight: 700;
  line-height: 1.3;
  color: var(--dark-gray-color);
}

.ruby-blog__article-layout__container__date {
  padding: 0.8rem 2rem;

  display: flex;
  text-align: left;
}

.ruby-blog__article-layout__container__date p {
  color: var(--secondary-text-color);
  font-size: 13px;
  font-weight: 700;
  font-family: var(--font-base);
}

.ruby-blog__article-layout__container__date span::before {
  content: "\02022";
  font-family: serif;
  padding: 0 8px;
}

.ruby-blog__article-layout__container__image-div {
  display: flex;
}

.ruby-blog__article-layout__container__image-div img {
  width: 100%;
  height: 100%;
}

.ruby-blog__article-layout__container__content {
  padding: 2rem 2rem 0rem 2rem;
  font-family: var(--font-base);
}

.ruby-blog__article-layout__container__content p {
  font-size: 15px;
  line-height: 1.8;
  font-family: var(--font-base);
  color: var(--dark-gray-color);
  margin: 1.5rem 0;

  width: 100%;
}

.ruby-blog__article-layout__container__content h2,
h1,
h3,
h4,
h5,
h6 {
  font-size: 23px;
  font-family: var(--font-alt);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.3;
  color: var(--dark-gray-color);
}

.ruby-blog__article-layout__container__content blockquote {
  border-left: 4px solid var(--dark-gray-color);
  color: var(--dark-gray-color);
  font-size: 18px;
  line-height: 1.4;
  font-family: var(--font-base);

  padding: 0rem 1rem;
}

.ruby-blog__article-layout__container__content ul {
  padding: 0.5rem;
  width: 100%;
}

.ruby-blog__article-layout__container__content li {
  margin: 0.3rem 0;
  font-family: var(--font-base);
  line-height: 1.8;
  font-size: 15px;
  width: 100%;
}

.ruby-blog__article-layout__container__content code {
  font-family: var(--font-alt);
  color: var(--dark-gray-color);
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 1px;
  font-size: 15px;

  width: 100%;
}

.ruby-blog__article-layout__container__content pre {
  width: 100%;
}

.ruby-blog__article-layout__container__content figcaption {
  width: 100%;
}

.ruby-blog__article-layout__container__content img {
  width: 100%;
  height: 100%;
}

.ruby-blog__article-layout__container__content p a img {
  width: unset;
  height: unset;
}

.ruby-blog__article-layout__container__content hr {
  width: 100%;
}

.ruby-blog__article-layout__container__hashtags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 0rem 2rem 2rem 2rem;
}

.ruby-blog__article-layout__container__hashtags div {
  margin-right: 0.3rem;
  font-size: 14px;
  font-weight: 700;
  font-family: var(--font-base);
  text-transform: capitalize;
  color: var(--dark-gray-color);
}

.ruby-blog__article-layout__container__socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.ruby-blog__article-layout__container__socialMedia div {
  margin-right: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  font-family: var(--font-base);

  margin-bottom: 1.5rem;
  color: var(--white-color);
  font-weight: 700;

  transition: opacity 0.3s var(--animation-base);
}

.ruby-blog__article-layout__container__socialMedia-facebook {
  background: #3b5998;
}

.ruby-blog__article-layout__container__socialMedia-twitter {
  background: #1da1f2;
}

.ruby-blog__article-layout__container__socialMedia-pinterest {
  background: #bd081c;
}

.ruby-blog__article-layout__container__socialMedia-linkedin {
  background: #0077b5;
}

.ruby-blog__article-layout__container__socialMedia-reddit {
  background: #ff4500;
}

.ruby-blog__article-layout__container__socialMedia-instagram {
  background: #833ab4;
}

.ruby-blog__article-layout__container__socialMedia:hover div {
  opacity: 0.2;
}

.ruby-blog__article-layout__container__socialMedia div:hover,
.ruby-blog__article-layout__container__socialMedia div:hover ~ a {
  opacity: 1;
}

@media screen and (max-width: 330px) {
  .ruby-blog__article-layout__container__head {
    padding: 1rem 0rem;
  }

  .ruby-blog__article-layout__container__date {
    padding: 0.8rem 1rem;
  }

  .ruby-blog__article-layout__container__content {
    padding: 2rem 1rem;
  }
}

.ruby-blog__author-info__container {
  display: flex;
  flex-direction: row;

  background: var(--white-color);

  margin: 1rem 0;
  padding: 1rem;
}

.ruby-blog__author-info__container__author-img-div {
  display: flex;
  justify-content: flex-start;

  flex: 0.5 1;
  margin-right: 1rem;
}

.ruby-blog__author-info__container__author-img-div img {
  width: 70px;
  height: 70px;

  border-radius: 50%;
  cursor: pointer;
}

.ruby-blog__author-info__container__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  -webkit-user-select: none;

          user-select: none;

  flex: 3 1;
}

.ruby-blog__author-info__container__content__author-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  text-align: left;
}

.ruby-blog__author-info__container__content__author-titleh4 {
  font-size: 18px;
  font-family: var(--font-alt);
  color: var(--dark-gray-color);
  font-weight: 800;
  line-height: 1.3;

  margin-top: 0;
  cursor: pointer;
}

.ruby-blog__author-info__container__content__author-para {
  font-size: 15px;
  font-family: var(--font-base);
  line-height: 1.65;
  color: var(--dark-gray-color);
}

.ruby-blog__author-info__container__content__author-address {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 1rem;
  color: var(--secondary-text-color);
  font-family: var(--font-alt);
  font-size: 11px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.ruby-blog__author-info__container__content__author-social {
  position: relative;
}

.ruby-blog__author-info__container__content__author-social-items {
  position: absolute;
  padding: 5px 11px;

  background: var(--black-color);
  color: var(--white-color);
  border-radius: 3px;
  min-width: -webkit-max-content;
  min-width: max-content;

  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.ruby-blog__author-info__container__content__author-social-items p {
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
  font-family: var(--font-base);
  margin: 0.7rem 0rem;
}

.ruby-blog__container__anotherPost-thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 1rem;
  flex: 1 1;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  position: relative;

  cursor: pointer;
  text-align: left;
  border-radius: 5px;

  transition: background-color 0.3s var(--animation-base);
  box-shadow: var(--shadow-base);
}

.ruby-blog__container__anotherPost-thumbnail::before {
  background-color: var(--black-color);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s var(--animation-base);
  border-radius: 5px;
  z-index: 1;
}

.ruby-blog__container__anotherPost-thumbnail:hover::before {
  opacity: 0.8;
}

.ruby-blog__container__anotherPost-thumbnail__post-type {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  z-index: 2;
  font-size: 11px;
  font-family: var(--font-base);
  opacity: 0.8;
  margin-bottom: 0.4rem;
}

.ruby-blog__container__anotherPost-thumbnail__articleTitle {
  font-size: 17px;
  z-index: 2;
  color: var(--white-color);
  font-weight: 700;
  font-family: var(--font-alt);
  line-height: 1.3;
}

.ruby-blog__home-container-articlePage {
  background: #f9f9f9;
  padding: 2rem 5rem;

  display: flex;
  flex-direction: row;
}

.ruby-blog__home-container-articlePage__content {
  flex: 2 1;
  margin-right: 1rem;
  padding-top: 1rem;

  display: flex;
  flex-direction: row;
}

.ruby-blog__home-container__content-article-suggestion {
  display: flex;
  flex-direction: column;

  flex: 0.7 1;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  top: 0;

  padding-top: 2rem;
  /* margin-right: 1rem; */

  height: -webkit-max-content;

  height: max-content;
}

.ruby-blog__home-container__content-display {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  flex: 2 1;
  width: 100%;
}

.ruby-blog__home-container__content-article-display {
  display: flex;
  flex-direction: column;

  background: var(--white-color);
  border-radius: 7px;
}

.ruby-blog__home-container__content-author-display {
  display: flex;

  background: var(--white-color);
  border-radius: 7px;

  margin-top: 2rem;
}

.ruby-blog__home-container__content-article-suggestion-heading {
  display: flex;
  margin: 0rem 0.9rem 1rem 1rem;
}

.ruby-blog__home-container__content-article-suggestion-heading p {
  font-size: 14px;
  font-family: var(--font-alt);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--secondary-text-color);
  letter-spacing: 1px;
}

.ruby-blog__home-container__content-post-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 7px;
  margin-top: 2rem;
}

.ruby-blog__home-container__content-post-links a {
  display: contents;
}

.ruby-blog__container__anotherPost-thumbnail:first-child {
  margin-right: 1rem;
}

@media screen and (max-width: 990px) {
  .ruby-blog__home-container__content-article-suggestion {
    display: none;
  }

  .ruby-blog__home-container-articlePage__content {
    margin-right: 0rem;
  }
}

@media screen and (max-width: 700px) {
  .ruby-blog__home-container-articlePage {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 500px) {
  .ruby-blog__home-container__content-post-links {
    flex-direction: column;
  }

  .ruby-blog__container__anotherPost-thumbnail:first-child {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 330px) {
  .ruby-blog__home-container-articlePage {
    padding: 2rem 0rem;
  }

  .ruby-blog__home-container__content-article-display {
    border-radius: 0;
  }
}

.ruby-blog__home-container__content-tags-display-div {
  margin: 0rem 2rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;

  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  -webkit-user-select: none;
          user-select: none;

  margin-bottom: 1rem;
  max-width: 250px;
  position: relative;
  left: 0%;
}

.ruby-blog__home-container__content-tags-display-div::-webkit-scrollbar {
  display: none;
}

.ruby-blog__home-container__content-tags-display-div__tag {
  margin-right: 1.3rem;
  font-family: var(--font-base);
  font-weight: 700;
  color: var(--secondary-text-color);
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;

  white-space: nowrap;

  transition: all 0.3s var(--animation-base);
}

.ruby-blog__home-container__content-tags-display-div__tag.active {
  background: var(--black-color);
  color: var(--white-color);
  padding: 5px 15px 7px;
  border-radius: 15px;
}

@media screen and (min-width: 600px) {
  .ruby-blog__home-container__content-tags-display-div {
    max-width: 580px;
  }
}

@media screen and (max-width: 300px) {
  .ruby-blog__home-container__content-tags-display-div {
    max-width: 200px;
    margin: 0 0.7rem;
  }
}

.ruby-blog__footer-container {
  display: flex;
  flex-direction: column;

  padding: 1rem;
  background: var(--white-color);
}

.ruby-blog__footer-container-head {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-family: var(--font-alt);
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--black-color);
  font-weight: 700;
}

.ruby-blog__footer-container-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  text-align: center;
  font-family: var(--font-base);
  font-size: 15px;
  letter-spacing: 0.5px;
  color: var(--black-color);
  font-weight: 600;

  margin-top: 1rem;
}

.ruby-blog__footer-container-copyright span {
  color: var(--secondary-text-color);
  font-family: var(--font-alt);
  margin-left: 0.3rem;
  font-size: 17px;
}

.ruby-blog__author-page-container__content {
  flex: 2 1;
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
}

.ruby-blog__author-page-container__content__author-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem;

  margin-bottom: 1rem;
}

.ruby-blog__author-page-container__content__image-div {
  width: 40px;
  height: 40px;
}

.ruby-blog__author-page-container__content__image-div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ruby-blog__author-page-container__content__author-name-div {
  margin-left: 1rem;
  color: var(--dark-gray-color);
  font-size: 20px;
  font-family: var(--font-alt);
  font-weight: 700;
  text-transform: capitalize;
}

.ruby_blog__notFound-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  background: #f9f9f9;
}

.ruby_blog__notFound-container__img-div {
  min-width: 300px;
  margin-top: 1rem;
}

.ruby_blog__notFound-container__img-div img {
  width: 100%;
}

.ruby_blog__notFound-container__info-div {
  margin: 0rem 0rem 2rem;
  padding: 0rem 2rem;
}

.ruby_blog__notFound-container__info-div h3 {
  font-size: 25px;
}

.ruby_blog__notFound-container__info-div h3 span {
  font-size: 50px;
  margin-right: 0.5rem;
}

.ruby_blog__notFound-container__info-div p {
  font-family: var(--font-alt);
  font-size: 15px;
  font-weight: 700;
  color: var(--secondary-text-color);
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0.3rem 0;
}

.ruby_blog__notFound-container__info-div a {
  color: #282850;
}

@media screen and (max-width: 310px) {
  .ruby_blog__notFound-container__img-div {
    width: 100%;
    min-width: 100%;
  }
}

.ruby-blog__about-site__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: #f9f9f9;
  padding: 1rem 5rem;
}

.ruby-blog__about-site__container__short-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--white-color);
  transition: 0.3s box-shadow var(--animation-base);

  margin-top: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 5px;
}

.ruby-blog__about-site__container__short-info:hover {
  box-shadow: var(--shadow-base);
}

.ruby-blog__about-site__container__short-info__head-div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ruby-blog__about-site__container__short-info__head-div h2 {
  font-size: 35px;
  font-family: var(--font-base);
  font-weight: 700;
}

.ruby-blog__about-site__container__short-info__head-div p {
  font-size: 15px;
  font-family: var(--font-alt);
  color: var(--secondary-text-color);
  font-weight: 700;
  margin-bottom: 1rem;
}

.ruby-blog__about-site__container__short-info__para-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.ruby-blog__about-site__container__short-info__para-div p {
  margin: 0.3rem 0;
  font-size: 17px;
  font-family: var(--font-alt);
  color: var(--dark-gray-color);
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.ruby-blog__about-site__container__credits {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background: var(--white-color);
  border-radius: 5px;
  padding: 1rem;
  transition: 0.3s box-shadow var(--animation-base);

  margin: 2rem 0rem 1rem;
}

.ruby-blog__about-site__container__credits:hover {
  box-shadow: var(--shadow-base);
}

.ruby-blog__about-site__container__credits__programmer-div,
.ruby-blog__about-site__container__credits__designer-div {
  width: 500px;
  background: #f9f9f9;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  margin: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

  padding: 2rem;
  border-radius: 5px;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div,
.ruby-blog__about-site__container__credits__designer-div__profile-div {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 0.5rem;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div img {
  width: 150px;
  height: 150px;
}

.ruby-blog__about-site__container__credits__designer-div__profile-div img {
  width: 110px;
  height: 110px;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__headinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.5rem;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__headinfo
  h2 {
  font-size: 28px;
  font-family: var(--font-base);
  font-weight: 700;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__headinfo
  p {
  font-family: var(--font-alt);
  font-size: 16px;
  font-weight: 700;
  color: var(--secondary-text-color);
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__parainfo {
  padding: 0rem 1rem;
  font-family: var(--font-alt);
  color: var(--dark-gray-color);
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 17px;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__social {
  margin-top: 1.5rem;
  padding: 0rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__social
  div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0.3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: opacity 0.3s var(--animation-base);
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__social-github,
.ruby-blog__about-site__container__credits__programmer-div__profile-div__social-ghost {
  background: #000;
  color: #fff;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__social-ruby,
.ruby-blog__about-site__container__credits__programmer-div__profile-div__social-instagram {
  background: #e11763;
  color: #fff;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__social:hover
  div {
  opacity: 0.2;
}

.ruby-blog__about-site__container__credits__programmer-div__profile-div__social
  div:hover,
.ruby-blog__about-site__container__credits__programmer-div__profile-div__social
  div:hover
  ~ a {
  opacity: 1;
}

@media screen and (max-width: 750px) {
  .ruby-blog__about-site__container {
    padding: 1rem 2rem;
  }

  .ruby-blog__about-site__container__credits__programmer-div,
  .ruby-blog__about-site__container__credits__designer-div {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .ruby-blog__about-site__container {
    padding: 1rem;
  }

  .ruby-blog__about-site__container__credits__programmer-div,
  .ruby-blog__about-site__container__credits__designer-div {
    padding: 2rem 0rem;
  }
}

@media screen and (max-width: 300px) {
  .ruby-blog__about-site__container {
    padding: 1rem 0.5rem;
  }

  .ruby-blog__about-site__container__credits__programmer-div,
  .ruby-blog__about-site__container__credits__designer-div {
    padding: 2rem 0rem;
  }

  .ruby-blog__about-site__container__short-info {
    padding: 1rem 0.7rem;
  }

  .ruby-blog__about-site__container__credits {
    padding: 1rem 0.5rem;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

.div__tag {
  display: flex;

  margin-bottom: 0.5rem;
  padding: 1rem 1rem 0 1rem;
}

.div__tag div {
  background: var(--black-color);
  color: var(--white-color);
  font-family: var(--font-base);
  font-size: 12px;
  font-weight: 700;
  padding: 0.15rem 0.5rem;
  border-radius: 15px;
  text-transform: capitalize;

  cursor: pointer;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  background: #0099ff;
  border: 4px solid #0099ff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

