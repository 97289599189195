.ruby-blog__home-container-articlePage {
  background: #f9f9f9;
  padding: 2rem 5rem;

  display: flex;
  flex-direction: row;
}

.ruby-blog__home-container-articlePage__content {
  flex: 2;
  margin-right: 1rem;
  padding-top: 1rem;

  display: flex;
  flex-direction: row;
}

.ruby-blog__home-container__content-article-suggestion {
  display: flex;
  flex-direction: column;

  flex: 0.7;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  top: 0;

  padding-top: 2rem;
  /* margin-right: 1rem; */

  height: max-content;
}

.ruby-blog__home-container__content-display {
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  flex: 2;
  width: 100%;
}

.ruby-blog__home-container__content-article-display {
  display: flex;
  flex-direction: column;

  background: var(--white-color);
  border-radius: 7px;
}

.ruby-blog__home-container__content-author-display {
  display: flex;

  background: var(--white-color);
  border-radius: 7px;

  margin-top: 2rem;
}

.ruby-blog__home-container__content-article-suggestion-heading {
  display: flex;
  margin: 0rem 0.9rem 1rem 1rem;
}

.ruby-blog__home-container__content-article-suggestion-heading p {
  font-size: 14px;
  font-family: var(--font-alt);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--secondary-text-color);
  letter-spacing: 1px;
}

.ruby-blog__home-container__content-post-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 7px;
  margin-top: 2rem;
}

.ruby-blog__home-container__content-post-links a {
  display: contents;
}

.ruby-blog__container__anotherPost-thumbnail:first-child {
  margin-right: 1rem;
}

@media screen and (max-width: 990px) {
  .ruby-blog__home-container__content-article-suggestion {
    display: none;
  }

  .ruby-blog__home-container-articlePage__content {
    margin-right: 0rem;
  }
}

@media screen and (max-width: 700px) {
  .ruby-blog__home-container-articlePage {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 500px) {
  .ruby-blog__home-container__content-post-links {
    flex-direction: column;
  }

  .ruby-blog__container__anotherPost-thumbnail:first-child {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 330px) {
  .ruby-blog__home-container-articlePage {
    padding: 2rem 0rem;
  }

  .ruby-blog__home-container__content-article-display {
    border-radius: 0;
  }
}
